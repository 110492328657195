import React,{useState, useEffect, useContext} from "react";
import { Container, Row, Col } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";

import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import ProfileLinks from "./ProfileLinks";
import { Contaxt } from "../../../Store";
import { apiBaseURL, apiURL, apiKey } from "../../../constant";
import Loader from "../../../Loader";

const UserAddress = () => {

  const {userData, changeUserData} = useContext(Contaxt);
  // const navigate = useNavigate();
  // const {pathname} = useLocation();
  const [addressData, setAddressData] = useState({
    name:"",
    city:"",
    mobileNo:"",
    state:"",
    address:"",
    country:'',
    landmark:"",
    pin:"",
    id:"",
    addressType:"update"
  });
  const [countries, setCountries] = useState([])
  const [addressList, setAddressList] = useState([])

  const [error, setError] = useState({type:"", msg:""})
  const [isLoading, setLoading] = useState(true)
  // useEffect(()=>{
  //   if(!userData.loginStatus){
  //     let url = btoa(pathname.substring(1)); 
  //     navigate('/login/'+url)
      
  //   }
   
  // })
  useEffect(() => {
    AOS.init({
      offset: 300,
      duration: 1000,
    });
    getCountriesList();
    getShippingAddressList();
    // if(userData.loginStatus){
      // setAddressData((prev)=>{
      //   return {
      //     ...prev,
      //     name:userData.name,
      //     city:userData.city,
      //     mobileNo:userData.mobile,
      //     state:userData.state,
      //     address:userData.address,
      //     country:userData.country,
      //     landmark:userData.landmark,
      //     pin:userData.pin,
      //   }
      // })
    // }
  }, []);

  function getCountriesList(){
    fetch(apiBaseURL+apiURL.countries,{
      method:'POST',
      headers:{
        'rskey' : apiKey,
      },
      body:JSON.stringify({"Country_List":"country"})
    }).then((response)=>response.json())
    .then( (result)=>{

      if( result.status==='1' ){
        setCountries(result.Country_Data)
        
      }else{
        
      }
    })
    .catch( (error)=>{
    })
  }

  function assembleAddressData(addressList){
    let currentAddress = {};
    for(let add of addressList){
      if(add.byDefault==1){
        currentAddress = add;
        break;
      }
    }
    setAddressData((prev)=>{
      return {
        ...prev,
        name:currentAddress.name,
        city:currentAddress.city,
        mobileNo:currentAddress.mobile,
        state:currentAddress.state,
        address:currentAddress.address,
        country:currentAddress.country,
        landmark:currentAddress.landmark,
        pin:currentAddress.pincode,
        id:currentAddress.id,
        addressType:'update'
      }
    })
    setAddressList(addressList);
    setLoading(false)
  }

  function getShippingAddressList(){
    fetch(apiBaseURL+apiURL.shippingAddressList,{
      method:'GET',
      headers:{
        'rskey' : apiKey,
        'utoken': userData.token
      },
    }).then((response)=>response.json())
      .then( (result)=>{

        if( result.status=='1' ){
          assembleAddressData(result.address_data);
        }else{
          setLoading(false)
        }
      })
      .catch( (error)=>{
        setLoading(false)
      })
  }

  function addOrUpdateAddress(e){
    e.preventDefault();
    if(addressData.name=="") {
        setError({type:"failed", msg:"Please fill the name!"})
        return;
    } else if(addressData.city=="") {
        setError({type:"failed", msg:"Please fill the city name!"})
        return;
    } else if(addressData.mobileNo=="") {
        setError({type:"failed", msg:"Please fill the mobile no!"})
        return;
    } else if(addressData.state=="") {
        setError({type:"failed", msg:"Please fill the state!"})
        return;
    } else if(addressData.email=="") {
        setError({type:"failed", msg:"Please fill the email!"})
        return;
    } else if(addressData.address=="") {
        setError({type:"failed", msg:"Please fill the address!"})
        return;
    } else if(addressData.country=="") {
        setError({type:"failed", msg:"Please fill the country name!"})
        return;
    } else if(addressData.pin=="") {
        setError({type:"failed", msg:"Please fill the pin code!"})
        return;
    }
    setError({type:"", msg:""})
    setLoading(true);
    if(addressData.addressType=='update'){
      updateAddress()
    } else {
      addAddress();
    }
  }

  function updateAddress(){
    
    const sendData = {
      "name":addressData.name,
      "mobile" : addressData.mobileNo,
      "address" : addressData.address,
      "state" : addressData.state,
      "city":addressData.city,
      "country":addressData.country,
      "pincode" : addressData.pin,
      "landmark":addressData.landmark,
      "address_id":addressData.id
    }
    fetch(apiBaseURL+apiURL.updateShippingAddress,{
      method:'POST',
      headers:{
        'rskey' : apiKey,
        'utoken': userData.token
      },
      body:JSON.stringify(sendData)
    }).then((response)=>response.json())
      .then( (result)=>{

        setLoading(false)
        console.log(result)
        if( result.status==='1' ){
          setError({type:"success", msg:"Successfully Updated!"})
          assembleAddressData(result.address_list);
          // changeUserData({
          //   name:addressData.name,
          //   city:addressData.city,
          //   mobile:addressData.mobileNo,
          //   state:addressData.state,
          //   address:addressData.address,
          //   country:addressData.country,
          //   landmark:addressData.landmark,
          //   pin:addressData.pin,
          // })
          
        }else{
          setError({type:"failed", msg:result.message})
          
        }
      })
      .catch( (error)=>{
        setLoading(false)
        setError({type:"failed", msg:"Network Error! try again"})
      })
  }

  function addAddress(){
    const sendData = {
      "name":addressData.name,
      "mobile" : addressData.mobileNo,
      "address" : addressData.address,
      "state" : addressData.state,
      "city":addressData.city,
      "country":addressData.country,
      "pincode" : addressData.pin,
      "landmark":addressData.landmark,
      
    }
    fetch(apiBaseURL+apiURL.addShippingAddress,{
      method:'POST',
      headers:{
        'rskey' : apiKey,
        'utoken': userData.token
      },
      body:JSON.stringify(sendData)
    })
    .then((response)=>response.json())
    .then( (result)=>{
      console.log(result)
      setLoading(false)
      if( result.status==='1' ){
        setError({type:"success", msg:"Successfully Added!"})
        assembleAddressData(result.address_list);
      
        
      }else{
        setError({type:"failed", msg:result.message})
        
      }
    })
    .catch( (error)=>{
      console.log(error)
      setLoading(false)
      setError({type:"failed", msg:"Network Error! try again"})
    })
  }

  function deleteAddress(item){
    setLoading(true);
    fetch(apiBaseURL+apiURL.deleteShippingAddress,{
      method:'POST',
      headers:{
        'rskey' : apiKey,
        'utoken': userData.token
      },
      body:JSON.stringify({address_id:item.id})
    })
    .then((response)=>response.json())
    .then( (result)=>{
      console.log(result)
      setLoading(false)
      if( result.status==='1' ){
        setError({type:"success", msg:"Successfully deleted!"})
        assembleAddressData(result.address_list);
      }else{
        setError({type:"failed", msg:result.message})
      }
    })
    .catch( (error)=>{
      console.log(error)
      setLoading(false)
      setError({type:"failed", msg:"Network Error! try again"})
    })
  }
  function changeData(value, type){
    setAddressData((prev)=>{
      return {...prev, [type]:value}
    })
  }

  

  function setAddressType(value){

    setAddressData((prev)=>{
      return {
        ...prev, 
        name:'',
        city:'',
        mobileNo:'',
        state:'',
        address:'',
        country:'',
        landmark:'',
        pin:'',
        addressType:value,
      }
    })
  }

  function setDefaultAddress(item){
    setLoading(true);
    setError({type:"", msg:""})
    fetch(apiBaseURL+apiURL.setDefaultAddress,{
      method:'POST',
      headers:{
        'rskey' : apiKey,
        'utoken': userData.token
      },
      body:JSON.stringify({"address_id":item.id})
    })
    .then((response)=>response.json())
    .then( (result)=>{
      setLoading(false)
      if( result.status=='1' ){
        // setAddressList(result.address_data);
        let newList = [...addressList];
        for(let add of newList){
          if(add.id==item.id){
            add.byDefault = 1
          } else {
            add.byDefault = 0;
          }
        }
        assembleAddressData(newList)
        // setAddressList((prev)=>{
        //   let newList = [...prev];
        //   for(let add of newList){
        //     if(add.id==item.id){
        //       add.byDefault = 1
        //     } else {
        //       add.byDefault = 0;
        //     }
        //   }
        //   return newList;
        // })
      }else{
        
      }
    })
    .catch( (error)=>{
      setLoading(false)
    })
  }

  if(!userData.loginStatus){
    return null; 
  }
  return(
    <div className="App">
      <Header/>
      <Navigation/>
        <ProfileLinks/>
        <div style={{position:"relative"}}>
        <Container>
          <div className="user_address_from" >
          <form onSubmit={addOrUpdateAddress}>
            <Row>
            <h2>Your Address</h2>
            {(error.type=="success" || error.type=="failed") &&
              <div style={{textAlign:'center', color:error.type=="success"?'green':'red'}}>{error.msg}</div>
            }
           
            <Col md={6}> 
              <div className="form-group">
                  <label className="user_heading">Name</label>
                  <input type="text" className="form-control login-mar" placeholder="" value={addressData.name} onChange={(e)=>{changeData(e.target.value, "name")}}/>
              </div>
            </Col>
            <Col md={6}> 
              <div className="form-group">
                  <label className="user_heading">City/Town</label>
                  <input type="text" className="form-control login-mar" placeholder="" value={addressData.city} onChange={(e)=>{changeData(e.target.value, "city")}}/>
              </div>
            </Col>
            </Row>
            <Row>
            <Col md={6}> 
              <div className="form-group">
                <label className="user_heading">Mobile</label>
                  <input type="text" className="form-control login-mar" placeholder="" value={addressData.mobileNo} onChange={(e)=>{changeData(e.target.value, "mobileNo")}}/>
              </div>
            </Col>
            <Col md={6}> 
              <div className="form-group">
                  <label className="user_heading">State</label>
                  <input type="text" className="form-control login-mar" placeholder="" value={addressData.state} onChange={(e)=>{changeData(e.target.value, "state")}}/>
              </div>
            </Col>
            </Row>
            <Row>
            <Col md={6}> 
              <div className="form-group">
                <label className="user_heading">Address(Area & Street)</label>
                  <input type="text" className="form-control login-mar" placeholder="" value={addressData.address} onChange={(e)=>{changeData(e.target.value, "address")}}/>
              </div>
            </Col>
            <Col md={6}> 
              <div className="form-group">
                  <label className="user_heading">Country</label>
                  {/* <input type="text" className="form-control login-mar" placeholder="" value={addressData.country} onChange={(e)=>{changeData(e.target.value, "country")}} readOnly/> */}
                  <select className="form-control feed-mar form-select" value={addressData.country} onChange={(e)=>{changeData(e.target.value, "country")}} >
                    <option hidden>--- Please Select ---</option>                    
                    {countries.map((item)=><option key={item.Country} value={item.Country}> {item.Country} </option>)}
                  </select>
              </div>
            </Col>
            </Row>
            <Row>
            <Col md={6}> 
              <div className="form-group">
                <label className="user_heading">Landmark</label>
                  <input type="text" className="form-control login-mar" placeholder="" value={addressData.landmark} onChange={(e)=>{changeData(e.target.value, "landmark")}}/>
              </div>
            </Col>
            <Col md={6}> 
              <div className="form-group">
                  <label className="user_heading">Pin Code</label>
                  <input type="text" className="form-control login-mar" placeholder="" value={addressData.pin} onChange={(e)=>{changeData(e.target.value, "pin")}}/>
              </div>
            </Col>
            </Row>
            <Row>
            <Col>
              <center>
                {addressData.addressType=="update"?
                  <button className="forget_btn" type="submit"> Update </button>:
                  <button className="forget_btn" type="submit"> Add </button>
                }
              </center>
            </Col>
            </Row>
            </form>
           

          </div>
        </Container>
        {addressData.addressType=="update" && 
        <div className="branch-bg">
          <Container>
            <Row>
              <Col md={3} xs={8}>
              <div className="order-hedi"> Shipping Address </div>
              </Col> 
              <Col md={9} xs={4}>
              <button className="add-address-btn" type="button" onClick={()=>{setAddressType('add')}}> Add Address </button>
              </Col> 
            </Row>
            <Row>
              
              {addressList.map((item)=>{
                let clsName = 'shipping-add-box1';
                let checked = false;
                if(item.byDefault==1){
                  clsName = 'shipping-add-box'
                  checked = true;
                }
                return(
                  <Col md={4} sm={6} key={item.id}> 
                    <div className={clsName}>
                      {/* {!checked &&
                        <button onClick={()=>{deleteAddress(item)}}>del</button>
                      } */}
                      <Row> 
                        {/* <Col md={1} xs={1}>
                        </Col> */}
                        <Col md={10} xs={10}>
                          <Row>
                          <label style={{display:"flex"}}>
                            <Col md={1} xs={1}>
                              <input type="radio" id="con" name="addressData" checked={checked} onChange={()=>{setDefaultAddress(item)}} />
                            </Col>
                            <Col md={10} xs={10}>
                              <div className="shi-address"> Name - {item.name}  </div>
                                <div className="shi-address"> Mobile - {item.mobile}</div>
                                <div className="shi-address"> Address - {item.address}</div>
                                <div className="shi-address"> City - {item.city}</div>
                                <div className="shi-address"> Sates - {item.state}</div>
                                <div className="shi-address"> Country - {item.country}</div>
                                <div className="shi-address"> Zip - {item.pincode}</div>

                            </Col>
                          </label>

                          </Row>
                        </Col>
                        <Col md={2} xs={2}>
                            {!checked &&
                              <div> <i onClick={()=>{deleteAddress(item)}} className="fa fa-trash-o empty-shi"></i> </div>
                            }
                        </Col>
                      </Row>
                    </div>
                  </Col>
                )
              })}
            {/* <Col md={4} sm={6}> 
              <div className="shipping-add-box">
                <Row> 
                <Col md={1} xs={1}>
                <input type="radio" id="con" name="ship" value="Argentina" />
                </Col>
                <Col md={9} xs={9}>
                <div className="shi-address"> Name - Mohan  </div>
                  <div className="shi-address"> Mobile - 9816087852  </div>
                  <div className="shi-address"> Address - Daryaganj, New Delhi </div>
                  <div className="shi-address"> City - New Delhi  </div>
                  <div className="shi-address"> Sates - Delhi  </div>
                  <div className="shi-address"> Country - Delhi  </div>
                  <div className="shi-address"> Zip - Delhi  </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={4} sm={6}> 
              <div className="shipping-add-box1">
                <Row> 
                <Col md={1} xs={1}>
                <input type="radio" id="con" name="ship" value="Argentina" />
                </Col>
                <Col md={9} xs={9}>
                <div className="shi-address1"> Name - Mohan  </div>
                  <div className="shi-address1"> Mobile - 9816087852  </div>
                  <div className="shi-address1"> Address - Daryaganj, New Delhi </div>
                  <div className="shi-address1"> City - New Delhi  </div>
                  <div className="shi-address1"> Sates - Delhi  </div>
                  <div className="shi-address1"> Country - Delhi  </div>
                  <div className="shi-address1"> Zip - Delhi  </div>
                  </Col>
                  <Col xs={2}>
                  <div> <i className="fa fa-trash-o empty-shi"></i> </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={4} sm={6}> 
              <div className="shipping-add-box1">
                <Row> 
                <Col md={1} xs={1}>
                <input type="radio" id="con" name="ship" value="Argentina" />
                </Col>
                <Col md={9} xs={9}>
                <div className="shi-address1"> Name - Mohan  </div>
                  <div className="shi-address1"> Mobile - 9816087852  </div>
                  <div className="shi-address1"> Address - Daryaganj, New Delhi </div>
                  <div className="shi-address1"> City - New Delhi  </div>
                  <div className="shi-address1"> Sates - Delhi  </div>
                  <div className="shi-address1"> Country - Delhi  </div>
                  <div className="shi-address1"> Zip - Delhi  </div>
                  </Col>
                  <Col xs={2}>
                  <div> <i className="fa fa-trash-o empty-shi"></i> </div>
                  </Col>
                </Row>
              </div>
            </Col> */}
            </Row>
          </Container>
        </div>
        }
        {isLoading && 
          <Loader width="80px" height="80px"/>
        }
        </div>
      <Footer/>
    </div>
  );
};
export default UserAddress;


