import React , {useState,useEffect,useContext,useRef} from "react";
import { Container, Row, Col,} from "react-bootstrap";
import { Link } from "react-router-dom";

import cuetLive from '../images/cuetLive.png';
import Digi from '../images/digi-text-app.png';
import Google_play from '../images/google-play.png';
import Payment from '../images/payment.png';
import mobileFoot from '../images/mobile-footer.png';
import footMail from '../images/foot-mail.png';
import footlocation from '../images/foot-location.png';

import { apiBaseURL, apiURL, apiKey } from './../../../constant';
import {Contaxt} from '../../../Store';
import Loader from "../../../Loader";



const Footer = () => {

  const [curYear, setCurYear] = useState(new Date().getFullYear());

  const {footerData} = useContext(Contaxt);
  /*const [chatData, setChatData] = useState({
    isOpened:false,
    msg:[]
  });*/
  //const [chatLoader, setChatLoader] = useState(false)
  //const [quesText, setQuesText] = useState("");
  const chatBox = useRef(null);
  const [subscribeData, setSubscribeData] = useState({
    text:"",
    status:"",
    msg:""
  });

  useState(()=>{

    //getAnswer('');

    const interval = setInterval(() => {
      setCurYear(new Date().getFullYear());
    }, 3600000); // Check every hour
    return () => clearInterval(interval);

  }, [])


  function setDataForSubscribe(value, type){
    setSubscribeData((prev)=>{

      return {...prev, [type]:value}
    })
   

  }

  function sendSubscription(){
    setSubscribeData((prev)=>{

      return {...prev, status:"loading"}
    })
    fetch(apiBaseURL+apiURL.subscribe,{
      method:'POST',
      headers:{
          'rskey':apiKey
      },
      body:JSON.stringify({"EmailId":subscribeData.text} )
    })
    .then( (result) => {
      result.json().then((res) => {
        if (res.status == "1") {
          setSubscribeData((prev)=>{
            return {...prev, status:"success", text:"", msg:res.message}
          })
        } else {
          setSubscribeData((prev)=>{
            return {...prev, status:"success", msg:res.message}
          })
        }
      });
    }).catch( (err)=>{
      console.log(err);
      setSubscribeData((prev)=>{
        return {...prev, status:"success", msg:"Network Error."}
      })
    })
  }

  /*function toogleChatBox(){
    setChatData((prev)=>{
      return {...prev, isOpened:!prev.isOpened}
    })
  }*/

  /*function setChatMsg(type, text){
    setChatData((prev)=>{
      let msg = [...prev.msg];
       msg.push({
         type:type,
         text:text,
       })

       return {...prev, msg}
    })
  }*/

  /*function selectOption(text){
    setChatMsg('user', text);
    // setQuesText("")
    getAnswer(text);

  }*/
  /*function setText(e){
    e.preventDefault();
    let text = quesText.trim();
    if(text==""){
      return
    }
    setChatMsg('user', text);
    setQuesText("")
    getAnswer(text);
  }*/
 /*
  async function getAnswer(ques){
    setTimeout(()=>{
        if(chatBox.current){
        chatBox.current.scrollTop  = chatBox.current.scrollHeight 
        }
      }, 100)
    setChatLoader(true);
    try {
      let response = await  fetch(apiBaseURL+apiURL.liveChat,{
          method:'POST',

          headers:{
              'rskey':apiKey,
          },
          body:JSON.stringify({"Live_Chat":ques})
        });
      let result = await response.json();
      setChatLoader(false);
      if(result.status=="1"){
        setChatData((prev)=>{
          let msg = prev.msg.map(item=>{
            return {...item, isActive:false}
          });
          msg.push(
            {
              type:'remote',
              replyText:result.Chat_reply_text,
              headings:result.Default_Heading_Text?.map((item)=>item.Default_Heading),
              text:result.LiveChat_Heading,
              ques:result.LiveChat_Questions?.map((item)=>item.Question),
              isActive:true,
            }
          )
          return {...prev, msg}
        })
        setTimeout(()=>{
          if(chatBox.current){
          chatBox.current.scrollTop  = chatBox.current.scrollHeight 
          }
        }, 100)
      } else {
        setChatLoader(false);
      }

    } catch (error) {
      console.log(error)
    }


  }
    */

  return(
    <div className="App">
      <footer>
        <div className="foot_section">
          <Row>
            <Col md={8} sm={12}> 
              <h2>We are always here to help you. Get in touch with us</h2>
                <div className="foot-nav">
                    
                </div>
            </Col>
            <Col md={2} sm={12}>
                <div className="add-input">
                  <input type="text" placeholder="“ Enter your email ”" value={subscribeData.text} onChange={(e)=>{setDataForSubscribe(e.target.value, 'text')}}/>
                  {subscribeData.status=="success" && 
                <sm>{subscribeData.msg}</sm>
              }
              </div>
              
            </Col>
            <Col md={2} sm={12}>
              {subscribeData.status=="loading"?
              <div className="btn-submit" style={{position:'relative', height:'60px'}}> 
                  <Loader width="35px" height="35px" backgroundColor="transparent"/>
              </div>:
              <div className="btn-submit">
                  <button className="btn-sub" type="button" onClick={sendSubscription}>Submit</button>
              </div>
              }
            </Col>
            
            <Col md={3} sm={6}>
                <div className="footer_links">
                <h3> Product & Services </h3>
                    <ul>
                      {footerData.productServices.map( (item)=>{                 
                        return(        
                          <li key={item.id}> <Link to={item.slug} > {item.title} </Link> </li> 
                        );
                        } )}

                        
                    </ul>
                </div>
            </Col>
            <Col md={3} sm={6}>
                <div className="footer_links">
                <h3> Terms of Use </h3>
                    <ul>
                      {footerData.termUses.map( (item)=>{                 
                        return(        
                          <li><Link to={item.slug} key={item.id}>{item.title} </Link> </li> 
                        );
                        } )}

                        
                    </ul>
                </div>
            </Col>
            <Col md={3} sm={6}>
                <div className="footer_links">
                <h3> Login </h3>
                    <ul>
                      {footerData.loginData.map( (item)=>{                 
                        return(        

                          <React.Fragment key={item.id}>
                          {item.urlType=='internal'?
                           <li> <Link to={item.slug} >{item.title} </Link> </li> :
                            <li> <a href={item.slug} target="_blank" onClick={()=>{console.log("lkdsfs")}}> {item.title}  </a>  </li>
                            }
                          </React.Fragment>  

                        
                          
                        );
                        } )}

                        
                    </ul>
                </div>
            </Col>
            <Col md={3} sm={6}>
                <div className="footer_links">
                    <h3> Contact Us </h3>
                    <div className="row">
                        <div className="col-md-2 col-sm-2 col-2">
                            <div className="link-address"> 
                            <a href="#"> <img src={mobileFoot} /> </a> 
                            </div>
                            <div className="link-address">
                            <a href="#"> <img src={footMail} /> </a>
                            </div>
                            <div className="link-address">
                            <a href="#"> <img src={footlocation} /> </a>
                            </div>
                        </div>
                        <div className="col-md-10 col-sm-10 col-10">
                            <div className="address">  +91-11-43585858 <br /> 23285568</div>
                            <div className="address"> info@rachnasagar.in </div>
                            <div className="address"> 4583/15, Daryaganj, <br /> New Delhi – 110002, INDIA </div>
                        </div>
                    </div>
                </div>
            </Col>
          </Row>
        </div>
        <div className="foot_img_section">
          <Row>
            <Col md={6} sm={6}>
              <div className="follow">
                <h4>Follow Us</h4>
                <div className="item-social">
                  {footerData.socialItems.map((item)=>{
                    return (
                      <div className="team-facebook" key={item.url}>
                        <a href={item.url} target="_blank">
                          <img src={item.image} alt={item.alt_title}/>
                        </a>
                      </div>
                    )
                  })}
                  
                </div>
              </div>
            </Col>
            <Col md={3} sm={6}>
            <div className="payment-m">
                <h4>Try Our App</h4>
              <div className="foot_img">
                
                  

                  <div className="foot-img-center"> 
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.rachnasagar&amp;hl=en_IN">  <img src={Google_play} /></a>
                </div>
                
              </div>
              </div>
            </Col>
            <Col md={3} sm={6}>
            <div className="payment-m">
                <h4>Payment Methods</h4>
                <div className="foot_img_pay">
                  <img className="payment" src={Payment} />
                </div>
                </div>
            </Col>
          </Row>
        </div>

        
         {/* CHAT BOX SECTION */}
         <a target="_blank" href="https://wa.aisensy.com/QEsnfY">  
              <div className="open-button"> <i className="fa fa-whatsapp smilefa" aria-hidden="true"></i> Chat with us </div>
            </a>
          {/* CHAT BOX SECTION */}

        <div className="foot-buttom">
          <p>© 1995 - {curYear} Rachna Sagar Pvt. Ltd. All rights reserved.</p>
        </div>

{/* cute section */}
    <div className="cuet">
        <a target="_blank" href="https://cuet.rachnasagar.in/">  <img className="stickerImg" src={cuetLive}/> </a>
    </div>
 {/* cute section */}

          
      </footer>
    </div>
  );
};
export default Footer;


