import React, { useState, useEffect, useContext} from 'react';
import { Container, Row, Col} from "react-bootstrap";

import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
 

import EbookPopup from "../Order/EbookPopup";

import { Link, useNavigate } from 'react-router-dom';
import { apiURL, apiBaseURL, apiKey } from '../../../constant';
import { Contaxt } from '../../../Store';
import Error from '../Error/Error';
import Loader from '../../../Loader';
import Helmet from "react-helmet";

const Order = () => {

    const [orderData, setOrderData] = useState({  
        "items":[],
        "orderDetail":{},
        "rewardsData":{},
        "shippingAddress":{},
        "eBookData":{},
        "isLoading":true,
    });
    const [seoData, setSeoData]  = useState({
      title:"",
      description:"",
      keywords:"",
      ogTags:{},
      twitterTags:{},
      isDone:false,
    })
    const [paymentMethod, setPaymentMethod] = useState("online") 
    const [offlineRes, setOfflineRes] = useState({
      status:'',
      msg:'',
      fun:''
    })
    const navigate = useNavigate();
    const {userData, resetUser} = useContext(Contaxt);
 
  useEffect(()=>{
    if(!userData.loginStatus){
      navigate('/account/login/'+btoa('orderDetail'))
    } 
  })
  useEffect(() => { 
    if(userData.loginStatus){
      getCheckoutDetail();
    }
    getSeoData();
    AOS.init({
      offset: 300,
      duration: 1000,
    });
  }, []);

  async function getSeoData(){
    try {
      let response = await  fetch(apiBaseURL+apiURL.orderSeo,{
          method:'POST',
          headers:{
              'rskey':apiKey,
          },
          body:JSON.stringify({"seo":"order"})
        });
      let result = await response.json();
      if(result.status=="1"){
        setSeoData({
          title:result.Meta_Title,
          description:result.Meta_Description,
          keywords:result.Meta_Keyword,
          ogTags:result.Og_Tag,
          twitterTags:result.Twitter_Tag,
          isDone:true,
        })
      } else {
        console.log(result.message)
       
      }

    } catch (error) {
      console.log(error)
    }
  }

  async function getCheckoutDetail(){

    // if(!userData.loginStatus){
    //   navigate('/login')
    // } else {
        try {
            const response = await fetch(apiBaseURL+apiURL.checkout, {
                method:"POST",
                headers:{
                    'rskey' : apiKey,
                    'utoken': userData.token
                }
            });
            const result = await response.json();
            console.log(result)
            if(result.status=="1"){
                setOrderData({
                    "items":result.order_list,
                    "orderDetail":result.order_details[0],
                    "rewardsData":result.rewards_data[0],
                    "shippingAddress":result.shipping_address,
                    "eBookData":result.ebook_data,
                    "isLoading":false,
                })
            } else {
              setOrderData((prev)=>{

                return {...prev, isLoading:false}
              })
              if(result.message=="Invalid TOKEN"){
                resetUser()
              } else {
                //show error 
              }
            }
        } catch (error) {

            console.log(error)
            setOrderData((prev)=>{
              return {...prev, isLoading:false}
            })
            //show error
        }   
    // }

  }
  
  function canclePopup(){
    setOfflineRes((prev)=>{
      return {
        ...prev,
        status:'',
        msg:"",
        fun:undefined
      }
    })
  }
  async function placeOrder(){
    if(paymentMethod==="bank"){
      setOfflineRes((prev)=>{
        return {
          ...prev,
          status:'loading',
          msg:"",
          fun:undefined
        }
      })
      try {
        const response = await fetch(apiBaseURL+apiURL.offlineOrder, {
            method:"POST",
            headers:{
                'rskey' : apiKey,
                'utoken': userData.token
            }
        });
        const result = await response.json();
        console.log(result)
        if(result.status=="1"){
          let data = btoa(JSON.stringify(result.data));
          navigate('/Profile/thankyou?type=offline&data='+data);
        } else {
          
          if(result.message=="Invalid TOKEN"){
            resetUser()
          } else {
            //show error 
            setOfflineRes((prev)=>{
              return {
                ...prev,
                status:'error',
                msg:result.message,
                fun:placeOrder
              }
            })
          }
        }
    } catch (error) {

        console.log(error)
        setOfflineRes((prev)=>{
          return {
            ...prev,
            status:'error',
            msg:'network error',
            fun:placeOrder
          }
        })
        //show error
    } 

    } else {
      navigate('/Profile/payment')
    }
  }



//========================================================
const [checked, setChecked] = React.useState(false); 
const [rewaction, setRewaction] = React.useState("add");
const [rewardsData, setRewardsData] = useState({
  status:false,
  text:'100',
  action:'',
  isLoading:false,
  msg:""
});

const [applyRewardBox, setApplyRewardBox] = React.useState("");
//setApplyRewardBox("add");

function rewardAplyChange(e) {

  if (e.target.checked==true) {
      setChecked("true");
      setRewaction("remove");  
      
  } else {
    setChecked("false"); 
    setRewaction("add");
    
  }
  //setChecked(e.target.checked);
  console.log(e.target.checked);
}

console.log(rewardsData);

async function applyRewards(){

  if(!userData.loginStatus){
    navigate('/account/login')
  }
  else{

    console.log("Rewards");
    console.log(userData.token);
    console.log(userData);
    // --------------------------------------------------------
    try {

      let sendData = {
        rewards: rewardsData.text,
        action: rewardsData.action
      }
      
      const response = await fetch(apiBaseURL+apiURL.rewardsManage, {
        method:"POST",
        headers:{
          'rskey' : apiKey,
          'utoken': userData.token
        },
        body:JSON.stringify({"rewards":rewardsData.text, "action":rewaction})
      });
      const result = await response.json();
      // console.log(result)
     
      if(result.status=="1"){

        setRewardsData((prev)=>{
          return {...prev, isLoading:false, msg:"Applied"}
        })

        getCheckoutDetail();
        
      } 
      else{       
        setRewardsData((prev)=>{
          return {...prev, isLoading:false,  msg:""}
        })        
      }

    } catch (error) {

        
        //show error
    }   


    //----------------------------------------------------------

  }
  
}

/*
if (orderData.orderDetail.rewards_redeem !== "0") {
  console.log("Before --- "+applyRewardBox);
  console.log("Aniket 1");

  setApplyRewardBox("checked");

  console.log("After --"+applyRewardBox);
}
  */

//console.log("ani--"+orderData.orderDetail.rewards_redeem);

//-------------------------------------------------



//=============================================================

  return(
    
    <div className="App">
      {seoData.isDone &&
        <Helmet>
            <title>{seoData.title} </title>
            <meta name="description" content={seoData.description} />
            <meta name="keywords" content={seoData.keywords} />
            <meta property="og:title" content={seoData.ogTags?.og_title} />
            <meta property="og:description" content={seoData.ogTags?.og_description} />
            <meta property="og:url" content={seoData.ogTags?.og_url} />
            <meta property="og:image" content={seoData.ogTags?.og_image_url} />
            <meta name="twitter:site" content="@rachnasagargrp"/> 
            <meta name="twitter:title" content={seoData.twitterTags?.twitter_title} />
            <meta name="twitter:description" content={seoData.twitterTags?.twitter_description} />
            <meta name="twitter:image" content={seoData.twitterTags?.twitter_image_url} />
        </Helmet>
      }
      
      {orderData.eBookData.ebook_iebook_status!="0" && 
        <EbookPopup/>
      }
      <Header/>
      <Navigation/>

      {orderData.isLoading?
          <div style={{position:'relative', height:'200px'}}>
          <Loader width="80px" height="80px" backgroundColor="transparent"/>
          </div>:
          <div className='order_boxbg'>
            <Container>
              <Row>
                <Col md={4} sm={6} style={{marginBottom:'8px'}}>
                <div className="order-box" data-aos="zoom-in">
                    <Row className='back-ship'>
                      <Col>
                      <h1 className='order-hedi'> Shipment Address </h1>
                      </Col>
                      <Col>
                      <div className='pen-edit'> <Link to="/Profile/address"> <i className="fa fa-pencil" aria-hidden="true"></i> </Link> </div>
                      </Col>
                    </Row>
                    <div className='address-order'>
                      <div> <span className='order-bold'>Name :</span> {orderData.shippingAddress.name}  </div>
                      <div> <span className='order-bold'>Address (Area & Street) :</span> {orderData.shippingAddress.address}  </div>
                      <div> <span className='order-bold'>City/Town :</span> {orderData.shippingAddress.city}  </div>
                      <div> <span className='order-bold'>State :</span> {orderData.shippingAddress.state}  </div>
                      <div> <span className='order-bold'>Country :</span> {orderData.shippingAddress.country}  </div>
                      <div> <span className='order-bold'>Pin Code :</span> {orderData.shippingAddress.pincode} , <b>Mobile</b> {orderData.shippingAddress.mobile} </div>
                    </div>
                </div>
                </Col>
                <Col md={4} sm={6} style={{marginBottom:'8px'}}>
                  <div className="order-box">
                    <Row className='back-ship'>
                      <Col>
                      <div className='order-hedi'> Order Details </div>
                      </Col>
                    </Row>
                    <div className='address-order'>
                      <Row>
                          <Col md={10} sm={8} xs={8}>
                              <div> Cart Sub Total </div>
                          </Col>
                          <Col md={2} sm={4} xs={4}>
                              <div className='right-order-text'> {orderData.orderDetail.total_mrp} </div>
                          </Col>

                          <Col md={10} sm={8} xs={8}>
                              <div> Discount ( - )  </div>
                          </Col>
                          <Col md={2} sm={4} xs={4}>
                              <div className='right-order-text'> {orderData.orderDetail.total_discount} </div>
                          </Col>

                          
                          {orderData.orderDetail.rewards_redeem !=0 &&
                          <>
                          <Col md={10} sm={8} xs={8}>
                              <div> Rewards Discount ( - )  </div>
                          </Col>
                          <Col md={2} sm={4} xs={4}>
                              <div className='right-order-text'> {orderData.orderDetail.rewards_redeem} </div>
                          </Col>
                          </>                          
                          }

                          <Col md={10} sm={8} xs={8}>
                              <div> Shipping Charges ( + ) </div>
                          </Col>
                          <Col md={2} sm={4} xs={4} >
                              <div className='right-order-text'> {orderData.orderDetail.shipping_charge} </div>
                          </Col>
                              <div className="order-border"></div>
                          <Col md={10} sm={6} xs={8}>
                              <div className='order-bold'> Payable Amount </div>
                          </Col>
                          <Col md={2} sm={6} xs={4}>
                              <div className='right-order-text order-bold'> {orderData.orderDetail.grand_total} </div>
                          </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
                <Col md={4} sm={6} style={{marginBottom:'8px', position:'relative'}}>
                  <div className="order-box" data-aos="zoom-in" >
                  {offlineRes.status=="loading" && 
                    <Loader width="60px" height="60px" />
                  }
                    <Row className='back-ship'>
                      <Col>
                      <div className='order-hedi'> Payment Method </div>
                      </Col>
                    </Row>
                    <div className='address-order'>
                      <Row>
                          <Col md={6} sm={8} xs={4}>
                          <div> Payment  </div>
                          </Col>
                          <Col md={6} sm={4} xs={8}>
                          <div className='right-order-text'>
                            <label style={{cursor:"pointer", margin:"2px 0px"}}>
                                <input checked={paymentMethod=="online"} className='paypros' type="radio" onChange={()=>{setPaymentMethod("online")}}/>
                                Online Payment 
                            </label>
                            <label style={{cursor:"pointer", margin:"2px 0px"}}>
                                <input checked={paymentMethod=="bank"} className='paypros' type="radio" onChange={()=>{setPaymentMethod("bank")}}/>
                                Bank Deposit
                            </label>
                          </div>
                          </Col>
                          <Col md={6} sm={12} xs={12}>
                            <Link to="/books/cbse"> <button className="shoping_btn" type="button"> Continue Shopping </button> </Link>
                          </Col>
                          <Col md={6} sm={12} xs={12} >
                          <button className="shoping_btn" type="button" onClick={()=>{placeOrder()}}> Place Order </button> 
                          </Col>
                      </Row>
                      {orderData.rewardsData.rewardStatus!=0 && 
                      <Row>
                        <Col md={12} sm={12} xs={12} >
                          
                                
                        
                          <div>
                              <input value = "apply" type = "checkbox"  onChange = {rewardAplyChange} onClick={()=>applyRewards()}   />
                              <span> Apply Rewards </span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                               
                              <span> [ Total Rewards - {orderData.rewardsData.rewardsCash} ]</span>
                          </div>
                          <div style={{color:'green'}}>{rewardsData.msg}</div>
                        
                        </Col>
                      </Row>
                     }

                      
                    </div>

                  </div>
                </Col>
                {paymentMethod==="bank" &&
                <Col md={4} sm={6} style={{marginBottom:'8px'}} >
                  <div className="order-box" style={{maxWidth:'500px'}} >
                      <Row className='back-ship'>
                        <Col>
                        <div className='thanksp'> 
                        Please deposit your amount & send the payment receipt through email for processing your order. </div>
                        </Col>
                      </Row>
                      <div className='thanks-detail'>
                        <Row>
                            <Col md={12} sm={12}>
                            <h2> Bank Details: </h2>
                            <div className='address-thx'> Account Name : <b> RACHNA SAGAR PVT. LTD. </b> </div>
                            <div className='address-thx'> Bank Name : <b> HDFC BANK LTD , Daryaganj, New Delhi-110002 </b> </div>
                            <div className='address-thx'> Bank Account No : <b> 03142790000055 </b> </div>
                            <div className='address-thx'> RTGS/IFSC Code : <b> HDFC0000314 </b> </div>
                            <div className='address-thx'> SWIFT CODE : <b> HDFC INBBDEL </b> </div>
                            </Col>
                        </Row>
                      </div>
                  </div>
                </Col>
                }
              </Row>
              {/* <Row style={{justifyContent:'center', margin:'24px 0 8px 0'}}>
                <Col md={5} sm={12} >
                  <div className="order-box" style={{maxWidth:'500px'}} >
                      <Row className='back-ship'>
                        <Col>
                        <div className='thanksp'> 
                        Please deposit your amount & send the payment receipt through email for processing your order. </div>
                        </Col>
                      </Row>
                      <div className='thanks-detail'>
                        <Row>
                            <Col md={12} sm={12}>
                            <h2> Bank Details: </h2>
                            <div className='address-thx'> Account Name : <b> RACHNA SAGAR PVT. LTD. </b> </div>
                            <div className='address-thx'> Bank Name : <b> HDFC BANK LTD , Daryaganj, New Delhi-110002 </b> </div>
                            <div className='address-thx'> Bank Account No : <b> 03142790000055 </b> </div>
                            <div className='address-thx'> RTGS/IFSC Code : <b> HDFC0000314 </b> </div>
                            <div className='address-thx'> SWIFT CODE : <b> HDFC INBBDEL </b> </div>
                            </Col>
                        </Row>
                      </div>
                  </div>
                </Col>
              </Row> */}
            </Container>
          </div>
        }
        <Container>
          <div className='order-list'>
            <h2> Book Order List </h2>
              <Row>
              {orderData.items.map((item, index)=>{

                  return(
                      <React.Fragment key={item.productId+item.product_type}>
                          <Col md={2} sm={2}>
                          <img className="img-thumbnail" src={item.main_image} />
                          </Col>
                          <Col md={10} sm={10} className="p-2">
                              <div>{item.product_title}</div>
                              <div>ISBN : {item.isbn}</div>
                              <div>Class : {item.class}</div>
                              <div>Subject : {item.subject}</div>
                              <div>Product Type : {item.book_type}</div>
                              <div>Quantity : {item.quantity}</div>
                              <div> 
                              <span className="booPrice">₹ {item.total_price}</span> 
                              <span className='order-rupess'>₹</span> <span className="linethrow">{item.total_mrp}</span> 
                          </div>
                          <div>You save ₹ {item.total_discount} <span style={{color:"#d98226"}}> ({Number(item.discount)*100/Number(item.mrp)}% off) </span> </div>
                          </Col>
                          {index!=orderData.items.length-1 && 
                            <div className="order-border"></div>
                          }
                      </React.Fragment>
                  )
              })}
            </Row>
          </div>
              
        </Container>
        {offlineRes.status=="error" && 
         <Error msg={offlineRes.msg} fun={offlineRes.fun} cancle={canclePopup} />
        }
      <Footer/>
    </div>
  );
};
export default Order;


