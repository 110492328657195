import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from "axios";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import { Contaxt } from '../../../Store';
import { apiBaseURL, apiURL, apiKey } from "../../../constant";
import Loader from "../../../Loader";
const EditWriteReview = () => {
    const [reviewData, setReviewData] = useState({
        stars: 0,
        headline: "",
        review: "",
    });
    const [error, setError] = useState({ type: "", msg: "" });
    const [isLoading, setLoading] = useState(false);

    const { userData } = useContext(Contaxt);
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        axios.post(`https://rachnasagar.in/api/reviews/list`, { bookid: params.bookId }, {
            headers: {
                'rskey': apiKey,
                'utoken': userData.token,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.data.status === "1" && response.data.reviews_data.length > 0) {
                    const review = response.data.reviews_data[0]; // ✅ Extract first review
                    setReviewData({
                        stars: review.star || 0,
                        headline: review.headline || "",
                        review: review.review || ""
                    });
                }
            })
            .catch(error => console.log("Error fetching review:", error))
            .finally(() => setLoading(false)); // ✅ Stop loading
    }, [params.bookId]); // ✅ Dependency added


    function setData(key, value) {
        setReviewData(prev => ({ ...prev, [key]: value }));
    }

    function submitReview(e) {
        e.preventDefault();
        if (reviewData.stars === 0) {
            setError({ type: "failed", msg: "Please select the stars!" });
            return;
        }

        setError({ type: "", msg: "" });
        setLoading(true);

        const sendData = {
            bookid: params.bookId,
            star: reviewData.stars,
            headline: reviewData.headline,
            review: reviewData.review
        };

        axios.post(`https://rachnasagar.in/api/reviews`, sendData, {
            headers: { 'rskey': apiKey, 'utoken': userData.token }
        })

            .then(response => {
                setLoading(false);
                if (response.data.status === '1') {
                    setError({ type: "success", msg: "Successfully Submitted!" });
                    navigate('/CustomerReview/' + params.bookId);
                } else {
                    setError({ type: "failed", msg: response.data.message });
                }
            })
            .catch(error => {
                setLoading(false);
                setError({ type: "failed", msg: "Network Error! Try again" });
            });
    }

    const selStar = { color: "#de7921" };

    return (
        <>
            <Header />
            <Navigation />
            <div className="feedback">
                <Container>
                    <Row>
                        <Col>
                            <div className="feedback_form" style={{ position: "relative" }}>
                                <h2 className="login-main">Edit Review</h2>
                                {(error.type === "success" || error.type === "failed") &&
                                    <div style={{ textAlign: 'center', color: error.type === "success" ? 'green' : 'red' }}>{error.msg}</div>
                                }
                                <form className="sign_up" onSubmit={submitReview}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h1 className="over-rating">Tap to Rate</h1>
                                            <div className="star">
                                                {[1, 2, 3, 4, 5].map((star) => (
                                                    <span key={star}
                                                        title={["Poor", "Fair", "Average", "Good", "Excellent"][star - 1]}
                                                        className="fa fa-star review-star"
                                                        onClick={() => setData("stars", star)}
                                                        style={reviewData.stars >= star ? selStar : undefined}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="login-hedi">Title</label>
                                                <input type="text" className="form-control feed-mar"
                                                    value={reviewData.headline}
                                                    onChange={(e) => setData("headline", e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="login-hedi">Review</label>
                                                <textarea className="form-control feed-mar"
                                                    rows="3"
                                                    value={reviewData.review}
                                                    onChange={(e) => setData("review", e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <center>
                                                <button className="forget_btn" type="submit" disabled={isLoading}>
                                                    {isLoading ? "Submitting..." : "Submit"}
                                                </button>
                                            </center>
                                        </div>
                                    </div>
                                </form>
                                {isLoading && <Loader width="80px" height="80px" />}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </>
    );
};

export default EditWriteReview;


