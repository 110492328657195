import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";
import { apiBaseURL, apiURL, apiKey } from "../../../constant";
import Loader from "../../../Loader";


const CustomerReview = () => {

  const params = useParams();
  const [reviewData, setReviewData] = useState({
    loading: true,
    list: []
  })
  useEffect(() => {
    getReviewData();
    AOS.init({
      offset: 300,
      duration: 1000,
    });
  }, []);

  function getReviewData() {
    fetch(apiBaseURL + apiURL.reviewList, {
      method: 'POST',
      headers: {
        'rskey': 'rspl',
      },
      body: JSON.stringify({ "bookid": params.bookId })
    })
      .then(response => response.json())
      .then((result) => {
        console.log(result)
        if (result.status == "1") {
          setReviewData((prev) => {
            return {
              ...prev,
              list: result.reviews_data,
              loading: false
            }
          })
        } else {
          setReviewData((prev) => {
            return {
              ...prev,
              list: [],
              loading: false
            }
          })
        }
      })
      .catch((error) => {
        console.log(error)
        setReviewData((prev) => {
          return {
            ...prev,
            list: [],
            loading: false
          }
        })
      });
  }
  return (
    <>
      <Header />
      <Navigation />
      <div className="feedback">
        <Container>
          <Row>
            <Col>
              <div className="customer-review">
                <h2 className="login-main">Customer Review</h2>
                <form className="sign_up">
                  <Row>
                    <Col md={12} sm={12}>
                      <span style={{ color: 'green' }}>
                        Thank you for your valuable feedback, We’re always here to improve.
                      </span>
                    </Col>
                    {reviewData.loading ?
                      <div style={{ position: 'relative', height: '100px' }}>
                        <Loader width="50px" height="50px" backgroundColor="transparent" />
                      </div> :
                      <Col md={12} sm={12}>
                        {reviewData.list.map((item) => {
                          let stars = [];
                          for (let i = 0; i < item.star; i++) {
                            stars.push(<span className="fa fa-star review-cos"></span>)
                          }
                          return (
                            <div className="reviews-section">
                              <h5>{item.Name}</h5>
                              {stars}
                              <span className="review-hedi"> {item.headline} </span>
                              <p>{item.review}</p>
                            </div>
                          )

                        })}
                      </Col>
                    }
                  </Row>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};
export default CustomerReview;
