import React, { useState, useEffect, useContext, lazy, Suspense } from "react";
import { apiBaseURL, apiURL, apiKey } from "./../../../constant";
import Loader from "../../../Loader";
import { Contaxt } from "../../../Store";
import CountUp from "react-countup";
import AOS from "aos";
import "aos/dist/aos.css";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

// ✅ Lazy Loading Components for Better Performance
const Banner = lazy(() => import("./Banner"));
const Bannerpoup = lazy(() => import("./Bannerpoup"));
const NewRelease = lazy(() => import("./NewRelease"));
const Bestsellers = lazy(() => import("./Bestsellers"));
const Booklunch = lazy(() => import("./Booklunch"));
const ShopBoard = lazy(() => import("./ShopBoard"));
const Newes_events = lazy(() => import("./News_events"));
const Awards = lazy(() => import("./Awards"));
const Testimonials = lazy(() => import("./Testimonials"));

const Home = () => {
  const [ourAssociates, setAssociates] = useState([]);
  const [reasonToFollow, setReasonToFollow] = useState([]);
  const [awardsItems, setAwardsItems] = useState([]);
  const [testimonialItems, setTestimonialItems] = useState([]);
  const [newsEvents, setNewsEvents] = useState([]);

  const { categoryItems, aboutLine, ourAuthor, booklunchItems } = useContext(Contaxt);

  useEffect(() => {
    getHomeBottomData();
    AOS.init({ offset: 300, duration: 1000 });
  }, []);

  // ✅ Optimized API Call
  async function getHomeBottomData() {
    try {
      const response = await fetch(`${apiBaseURL}${apiURL.bottomData}`, {
        method: "GET",
        headers: { rskey: apiKey },
      });

      const result = await response.json();

      if (result.status === "1") {
        const { our_associates = [], reason_to_follow = [], awards = [], testimonials = [], news_events = [] } = result.sections || {};

        setAssociates(our_associates);
        setReasonToFollow(reason_to_follow);
        setAwardsItems(awards);
        setTestimonialItems(testimonials);
        setNewsEvents(news_events);
      }
    } catch (error) {
      console.error("API Fetch Error:", error);
    }
  }

  return (
    <Suspense fallback={<Loader />}>
      <div className="App">
        <Banner />
        <div className="bg-img">
          <Bannerpoup />
          <NewRelease />
          <Bestsellers />
          {booklunchItems.items.length !== 0 && <Booklunch />}
          <ShopBoard />

          {/* ✅ Our Associates Section */}
          <div className="associates">
            <Container fluid>
              <div className="main_slider_1">
                <h2 className="rel-hedi-1">Our Associates</h2>
                <Row className="display-center">
                  {ourAssociates.map((item, index) => (
                    <Col sm={6} md={3} key={item.id}>
                      <div className="asso_ciates">
                        <img src={item.imagePath} alt={item.Title} />
                        <div className="associates_btn">
                          {item.urlType === "internal" ? (
                            <Link to={item.url}>Read More</Link>
                          ) : (
                            <a href={item.url} target="_blank" rel="noopener noreferrer">
                              Read More
                            </a>
                          )}
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </Container>
          </div>

          {/* ✅ Our Authors Section */}
          <div className="our-authors">
            <Container fluid>
              <div className="main_slider">
                <h2 className="rel-hedi-1">Our Authors</h2>
                <Row>
                  {ourAuthor.map((item) => (
                    <Col md={3} sm={4} xs={12} key={item.id}>
                      <div className="author">
                        <figure className="effect-apollo">
                          <Link to="/renowedauthors">
                            <img src={item.image} alt={item.Title} />
                          </Link>
                          <figcaption></figcaption>
                        </figure>
                        <Link to="/renowedauthors">
                          <div className="title">{item.Title}</div>
                          <p dangerouslySetInnerHTML={{ __html: item.desp }} />
                        </Link>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </Container>
          </div>

          {/* ✅ Reasons to Follow Section */}
          <div className="reasons-us">
            <Container fluid>
              <div className="main_slider_1">
                <h2 className="rel-hedi-1">Reasons to Follow Us</h2>
                <Row>
                  {reasonToFollow.map((item) => (
                    <Col md={3} sm={6} xs={6} className="mb-2 mt-2" key={item.id}>
                      <div className="reasons-bg">
                        <img src={item.image} alt={item.Title} />
                        <h4>{item.Title}</h4>
                        <CountUp start={0} end={item.total} suffix=" +" delay={0} duration={9.12}>
                          {({ countUpRef }) => <h5 ref={countUpRef}></h5>}
                        </CountUp>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </Container>
          </div>

          {/* ✅ Other Sections */}
          <Awards awardsItems={awardsItems} />
          <Newes_events newsEvents={newsEvents} />
          <Testimonials testimonialItems={testimonialItems} />
        </div>
      </div>
    </Suspense>
  );
};

// ✅ Memoized Home Component to Prevent Unnecessary Re-renders
const MemoizedHome = React.memo(Home);
export default MemoizedHome;
