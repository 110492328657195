import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import Header from "../Coman/Header";
import Navigation from "../Coman/Navigation";
import Footer from "../Coman/Footer";

import ProfileLinks from "./ProfileLinks";
import { apiBaseURL, apiURL, apiKey } from '../../../constant'
import { Contaxt } from "../../../Store";
import Loader from "../../../Loader";
import Error from "../Error/Error";

const UserOrder = () => {
  const { userData } = useContext(Contaxt);
  const [orderData, setOrderData] = useState({
    isLoading: true,
    list: [],
    pagination: { links: [], nextPage: null, prevPage: null, currentPage: 1 }
  });
  const [error, setError] = useState({ status: false, msg: "", fun: undefined });

  useEffect(() => {
    getMyOrders()
    AOS.init({
      offset: 300,
      duration: 1000,
    });
  }, []);

  function removeError() {
    setError({ status: false, msg: "", fun: undefined })
  }


  function getMyOrders() {
    if (!orderData.isLoading) {
      setOrderData((prev) => {
        return { ...prev, isLoading: true }
      })

    }
    fetch(apiBaseURL + apiURL.myOrders, {
      method: 'GET',
      headers: {
        'rskey': apiKey,
        'utoken': userData.token
      }
    }).then((response) => response.json())
      .then((result) => {

        console.log(result)

        if (result.status === '1') {
          setOrderData((prev) => {
            return {
              ...prev,
              isLoading: false,
              list: result.orders,
              pagination: {
                links: result.pagination?.links,
                nextPage: result.pagination?.next_page_url,
                prevPage: result.pagination?.prev_page_url,
                currentPage: result.pagination?.current_page
              }
            }
          })
        } else {
          setError({ type: "failed", msg: result.message, fun: undefined })
          setOrderData((prev) => {
            return { ...prev, isLoading: false }
          })
        }
      })
      .catch((error) => {
        console.log(error);
        setOrderData((prev) => {
          return { ...prev, isLoading: false }
        })
        setError({ type: "failed", msg: "Network Error! try again", fun: getMyOrders })
      })
  }

  function goToPage(url) {
    if (!orderData.isLoading) {
      setOrderData((prev) => {
        return { ...prev, isLoading: true }
      })

    }
    fetch(url, {
      method: 'GET',
      headers: {
        'rskey': apiKey,
        'utoken': userData.token
      }
    }).then((response) => response.json())
      .then((result) => {

        console.log(result)

        if (result.status === '1') {
          setOrderData((prev) => {
            return {
              ...prev,
              isLoading: false,
              list: result.orders,
              pagination: {
                links: result.pagination?.links,
                nextPage: result.pagination?.next_page_url,
                prevPage: result.pagination?.prev_page_url,
                currentPage: result.pagination?.current_page
              }
            }
          })
        } else {
          setError({ type: "failed", msg: result.message, fun: undefined })
          setOrderData((prev) => {
            return { ...prev, isLoading: false }
          })
        }
      })
      .catch((error) => {
        console.log(error);
        setOrderData((prev) => {
          return { ...prev, isLoading: false }
        })
        setError({ type: "failed", msg: "Network Error! try again", fun: getMyOrders })
      })
  }
  return (
    <>
      <Header />
      <Navigation />
      <ProfileLinks />
      <Container>
        {orderData.isLoading ?
          <div style={{ position: 'relative', height: '200px' }}>
            <Loader width="80px" height="80px" backgroundColor="transparent" />
          </div> :
          <>
            {orderData.list.length == 0 &&
              <div style={{ textAlign: "center" }}>No Order Found!</div>
            }
            <div className="user-order-hedi"> Your Orders </div>
            {orderData.list.map(item => {
              // console.log('order-details', orderData.list);

              return (
                <Row key={item.order_id}>
                  <Col>
                    <div className="user-order-box">
                      <Row>
                        <Col md={2} xs={6}>
                          <div className="ordProTxt"> Order Date </div>
                          <div className="ordProTitle">{item.order_date}</div>
                        </Col>
                        <Col md={2} xs={6}>
                          <div className="ordProTxt"> Order Id </div>
                          <div className="ordProTitle">{item.order_id}</div>
                        </Col>
                        <Col md={2} xs={6}>
                          <div className="ordProTxt"> Order Status </div>
                          <div className="ordProTitle">{item.order_status}</div>
                        </Col>
                        <Col md={2} xs={6}>
                          <div className="ordProTxt"> Amount </div>
                          <div className="ordProTitle"> {item.total_amount} </div>
                        </Col>
                        <Col md={2} xs={6}>
                          <div className="ordProTxt"> Order From </div>
                          <div className="ordProTitle"> {item.order_from} </div>
                        </Col>
                        <Col md={2} xs={6}>
                          <div className="ordProTxt"> Invoice </div>
                          <div className="ordProTitle">
                            <a target="blank" href={`https://api.rachnasagar.in/digitextapp/order-invoice/${item.order_id}`}><b>Download </b></a>

                          </div>
                        </Col>
                      </Row>
                      <hr />
                      <div className="order-light">
                        <Row>
                          <Col md={2} xs={6}>
                            <b>S.n.</b> </Col>
                          <Col md={6} xs={6}>
                            <b>Items</b> </Col>
                          <Col md={2} xs={6}>
                            <b>Order Type </b> </Col>
                          <Col md={2} xs={6} className="right-login">
                            <Link to={`/Profile/tracking/${item.order_id}`} state={item}><b>Track</b></Link>

                          </Col>
                          <div className="light-border"></div>
                        </Row>
                        {item.order_items.map((orderList, index) => {

                          return (
                            <Row key={orderList.bookid + orderList.book_type}>
                              <Col md={2}>
                                <div className="main-order-box">
                                  <div> {index + 1} </div>
                                  <img style={{ width: "95px" }} src={orderList.image} />
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="main-order-box1">
                                  <p>
                                    {orderList.book_title}
                                  </p>
                                </div>
                              </Col>
                              <Col md={2}>
                                <div className="main-order-box1"> {orderList.book_type} </div>
                              </Col>
                              <Col md={2}>


                                {/* <div className="main-order-box1">

                                  {item.order_status === "Success" && item.isDelivered === 1 ? (

                                    <Link to={"/Profile/writeReview/" + orderList.id}>
                                      <button type="button" className="btn btn-secondary">
                                        Write Review
                                      </button>
                                    </Link>
                                  )

                                    : orderList.hasReviewed === 1 ? (
                                      <span style={{ color: "green", fontWeight: "bold" }}>Rated</span>
                                    ) : (
                                      <span>Not Delivered Yet</span>

                                    )}
                                </div> */}

                                <div className="main-order-box1">
                                  {orderList.hasReviewed === 1 ? (
                                    <span style={{ color: "green", fontWeight: "bold" }}>Rated <span className="px-3"><Link to={"/Profile/EditWriteReview/" + orderList.id}><u>edit</u></Link></span></span>
                                  ) : (
                                    item.order_status === "Success" && item.payment_status === "Success" ? (
                                      <Link to={"/Profile/writeReview/" + orderList.id}>
                                        <button type="button" className="btn btn-secondary">
                                          Write Review
                                        </button>
                                      </Link>
                                    ) : (
                                      <span>Not Delivered Yet</span>
                                    )
                                  )}
                                </div>




                              </Col>
                            </Row>
                          )
                        })}
                      </div>
                    </div>
                  </Col>
                </Row>
              )
            })}
          </>
        }
        {orderData.pagination?.links.length > 1 &&
          <nav className="pagina-outer" aria-label="Page navigation">
            <ul className="pagina">
              <li className="page-item" onClick={() => { if (orderData.pagination.prevPage) goToPage(orderData.pagination.prevPage) }}>
                <a className="page-link" aria-label="Previous">
                  <span aria-hidden="true">«</span>
                </a>
              </li>
              {orderData.pagination.links.map((item) => {
                let activeCls = "";
                if (item.active) {
                  activeCls = 'active'
                }
                return (
                  <li className={`page-item ${activeCls}`} onClick={() => { if (activeCls == "" && item.label != '...') goToPage(item.url) }}>
                    <a className="page-link">{item.label}</a>
                  </li>
                )
              })}

              <li className="page-item" onClick={() => { if (orderData.pagination.nextPage) goToPage(orderData.pagination.nextPage) }}>
                <a className="page-link" aria-label="Next">
                  <span aria-hidden="true">»</span>
                </a>
              </li>
            </ul>
          </nav>
        }

      </Container>
      {error.status &&
        <Error msg={error.msg} cancle={removeError} fun={error.fun} />
      }
      <Footer />
    </>
  );
};
export default UserOrder;